body {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100vh;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.watermark-logo {
    position: fixed;
    width: 600px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 0.13;
    z-index: 1000;
    font-size: 20rem; /* Increased font size significantly */
    font-weight: bold;
    color: #3B82F6;
    letter-spacing: 1rem;
    text-transform: uppercase;
    pointer-events: none;
    user-select: none;
    white-space: nowrap;
    text-shadow:
        3px 3px 6px rgba(0,0,0,0.2),
        -3px -3px 6px rgba(255,255,255,0.5);
    /*animation:*/
    /*    pulse 4s infinite alternate,*/
    /*    float 6s ease-in-out infinite;*/
}

@keyframes pulse {
    0% {
        opacity: 0.05;
        transform: translate(-50%, -50%) rotate(-15deg) scale(0.95);
    }
    100% {
        opacity: 0.12;
        transform: translate(-50%, -50%) rotate(-15deg) scale(1.05);
    }
}

@keyframes float {
    0%, 100% {
        transform: translate(-50%, -50%) rotate(-15deg) translateY(0);
    }
    50% {
        transform: translate(-50%, -50%) rotate(-15deg) translateY(-20px);
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border {
    box-shadow: rgba(9, 30, 66, 0.31) 0 0 1px 0, rgba(9, 30, 66, 0.25) 0 1px 1px 0;
    border-radius: 10px;
}

.container {
    display: flex !important;
    flex-direction: column !important;
    position: relative;
    bottom: 10px;
    left: 50%;
    align-items: center;
    padding: 10px;
}

.container_top {
    display: flex;
    justify-content: center;
    height: 95vh; /* This makes the container take the full height of the viewport */
    width: 100%; /* Adjust the width as needed */
    margin: 0 auto; /* This centers the container horizontally */
    padding: 10px;
    box-sizing: border-box; /* This ensures that padding doesn't add to the total width */
}

.menuItem {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.service-item {
    display: flex;
    flex: 1;
    margin: 2px 0;
    padding: 4px;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.service-item:hover {
    background-color: #32E0A1;
    cursor: pointer;
}

/* Base styling for the table */
table.zaia {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  margin: 20px 0;
}

/* Styling for the table headers */
table.zaia th {
  background-color: #4CAF50;
  color: white;
  text-align: left;
  padding: 10px;
}

/* Styling for the table cells */
table.zaia td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

/* Zebra striping for table rows */
table.zaia tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Hover effect for table rows */
table.zaia tr:hover {
  background-color: #ddd;
}

/* Responsive table */
@media screen and (max-width: 600px) {
  table.zaia, table.zaia thead, table.zaia tbody, table.zaia th, table.zaia td, table.zaia tr {
    display: block;
  }
  table.zaia tr {
    margin-bottom: 10px;
  }
  table.zaia td, table.zaia th {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }
  table.zaia td::before, table.zaia th::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: calc(50% - 20px);
    text-align: left;
    font-weight: bold;
  }
}






