.editorWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.editorToolbar {
  flex-shrink: 0; /* Prevent the toolbar from shrinking */
  z-index: 100; /* Ensure the toolbar is on top */
  background-color: #f3f0f0;
}

.editorContent {
  flex: 1 1 0;
  max-height: 600px;
  min-height: 600px;
  overflow-y: auto; /* Enable scrolling for the content area */
  padding: 10px;
}