.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

/* reduce image width when an image is embedded into the chatdialog */
.img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin-left: 0 !important;
}

/* button to import context app_theme from the local context app_theme store */
.insert-context-button {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 1;
}

/*Chat styling*/
.ChatContainer {
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
}


/* SCROLL BAR UPDATE - THIN instead of default */
/* For Chrome, Safari, and newer versions of Edge */
::-webkit-scrollbar {
    width: 2px; /* or any other dimension */
    height: 10px;
}

::-webkit-scrollbar-track {
    background: transparent; /* optional: just to be explicit */
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* color of the scroll thumb */
    border-radius: 4px; /* roundness of the scroll thumb */
    border: 2px solid transparent; /* creates padding around scroll thumb */
}

/* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: darkgrey transparent; /* thumb and track color */
}

/* EDITOR WYSIWYG  */
.card-body {
  /* max-height: calc(60vh); */
  overflow-y: auto; /* of 'scroll' als je altijd een scrollbar wilt */
}

/* ---------------------- Overrule MUI default styles -------------------------*/
.MuiCardContent-root {
    margin-left: 10px;
    padding: 5px !important;
}

/* ---------------------- Default style for all anchor tags -------------------------*/
/*a {*/
/*  display: inline-block;*/
/*  padding: 10px 20px;*/
/*  background-color: #3498db;*/
/*  color: #ffffff;*/
/*  text-decoration: none;*/
/*  font-family: Arial, sans-serif;*/
/*  font-size: 16px;*/
/*  font-weight: bold;*/
/*  border-radius: 5px;*/
/*  transition: all 0.3s ease;*/
/*  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);*/
/*  position: relative;*/
/*  overflow: hidden;*/
/*}*/

/*a:before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: -100%;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: linear-gradient(*/
/*    120deg,*/
/*    transparent,*/
/*    rgba(255, 255, 255, 0.2),*/
/*    transparent*/
/*  );*/
/*  transition: all 0.5s;*/
/*}*/

/*a:hover {*/
/*  background-color: #2980b9;*/
/*  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);*/
/*  transform: translateY(-2px);*/
/*}*/

/*a:hover:before {*/
/*  left: 100%;*/
/*}*/

/*a:active {*/
/*  transform: translateY(0);*/
/*  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);*/
/*}*/

/*a:focus {*/
/*  outline: none;*/
/*  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.5);*/
/*}*/

/*!* Optional: Reset for specific cases where you don't want this style *!*/
/*a.no-style {*/
/*  all: unset;*/
/*  cursor: pointer;*/
/*}*/